// @flow
import * as React from "react";

import { NavLink } from "react-router-dom";

const Navigation = {
  Affiliate: function (): React.Node {
    return (
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink to="/dashboard" className="nav-link">
            Dashboard
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/campaigns" className="nav-link">
            Campaigns
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/reports" className="nav-link">
            Reports
          </NavLink>
        </li>
      </ul>
    );
  },
  Manager: function (): React.Node {
    return (
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink to="/dashboard" className="nav-link">
            Dashboard
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/campaigns" className="nav-link">
            Campaigns
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/landings" className="nav-link">
            Landings
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/offers" className="nav-link">
            Offers
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/domains" className="nav-link">
            Domains
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/reports" className="nav-link">
            Reports
          </NavLink>
        </li>
      </ul>
    );
  },
};

export default Navigation;
